<script>
import WikiModal from "../components/Wiki";
import mainStable from "commonsfrontend/src/maker/vue-files/layout/main-stable";
export default {
  extends: mainStable,
  components: { WikiModal }
};
</script>

<style lang="scss">
$primaryBlue: #ff00b3;
$secondaryBlue: #f953c6;
$thirdBlue: #b91d73;
$middleBlue: #b91d73;

.design-group .title {
  // color: #fff !important;
  //  background: rgb(201, 214, 255) !important;
}

header#CodementumNavbarHeader {
  img {
    filter: brightness(0) invert(1);
  }

  background-color: unset !important;
  background: $middleBlue !important;

  .user-settings button {
    color: $primaryBlue;

    &.activated,
    &:hover {
      background: $primaryBlue;
      color: white;
      & svg {
        fill: white;
      }
    }

    & svg {
      fill: $primaryBlue;
    }

    &.disabled {
      color: #802959;
      background-color: #ce80ab !important;
      svg {
        fill: #802959;
      }
    }
  }
  ul li .dropbtn:hover {
    background-color: $primaryBlue !important;
  }
}
.btn-mission {
  background-color: $primaryBlue !important;
  &:hover {
    color: $secondaryBlue !important;
  }
}

section.project-info div.page-missions div.mission-warning {
  background: white;
  margin-top: 1em;
  padding: 0.5em;
  border-radius: 4px;
  border: 1px solid $primaryBlue;
  svg {
    fill: $primaryBlue;
    margin-right: 0.2em;
  }
  p {
    display: inline-block;
    margin: 0;
  }
}

.top {
  border-bottom: 1px solid $middleBlue !important;
  .header:after {
    background-color: $middleBlue !important;
  }
}
.top-tasklist .top-tasklist-content ul li button.done {
  background-color: $primaryBlue !important;
}
.top-tasklist .top-tasklist-content ul li button.current {
  background-color: $secondaryBlue !important;
}
</style>