<template>
  <div>
    <b-modal
      id="gamemaker-wiki-modal"
      scrollable
      size="xl"
      title="AppMaker Wiki"
      hide-footer
      hide-header
      hide-backdrop
      header-close-content="X"
    >
      <h3 class="float-left">Welcome to Game Maker Wiki!</h3>
      <button
        @click="$bvModal.hide('appmaker-wiki-modal')"
        class="float-right close"
        type="button"
        aria-label="Close"
      >X</button>
      <hr />
      {{ currentPage }} Coming Soon
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "Wiki",
  data() {
    return {
      currentPage: null
    };
  },
  mounted() {
    this.$root.$on("WikiModal::show", ({ keyword } = {}) => {
      this.currentPage = keyword;
      this.$bvModal.show("gamemaker-wiki-modal");
    });
  }
};
</script>

<style>
.modal-header {
  color: white;
}
</style>
